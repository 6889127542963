import * as React from "react";
import { Link } from "gatsby";
import Nav from "../Nav/Nav.js";
import logo from "../../images/logo.svg";
import { header, logoContainer } from "./Header.module.scss";

/**
 * Returns header section containing logo + nav component
 */

export default function Header({ homepageLink }) {
  return (
    <header className={`${header}`}>
      <Link
        className={logoContainer}
        title="logo"
        to={homepageLink ? `${homepageLink}` : `/`}
      >
        <img
          src={logo}
          alt="Rocket Referrals' logo"
          style={{ height: "1.5rem" }}
        />
      </Link>
      <Nav />
    </header>
  );
}
