import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  ul,
  navLink,
  nav,
  dropdown,
  menu,
  button,
  menuLink,
  mobileMenuIcon,
  mobileMenu,
  mobileNav,
  mobileNavItems,
  mobileLink,
  mobileButton,
  mobileMenuClose,
} from "./Nav.module.scss";

/*
 * Returns a simple Nav which can be found in the header component via in Layout
 */

export default function Nav() {
  //Allows dropdown menu links to auto populate new entries when a new article is created.

  const getLinkTitles = useStaticQuery(graphql`
    query Links {
      allStrapiArticles(sort: { order: ASC, fields: strapiId }) {
        nodes {
          article_title
          slug
          id
        }
      }
    }
  `);

  const linkTitles = getLinkTitles.allStrapiArticles.nodes;

  //states to handle dropdown menu and mobile menu

  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = React.useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState(false);

  //creates a ref on the dropdown menu element to know what is in and out of bounds.

  const navRef = React.useRef(null);

  //sets event listener to listen for click outside of dropdown menu. Removes listener on unmount.

  React.useEffect(() => {
    document.addEventListener("click", clickOff);
    return () => {
      document.removeEventListener("click", clickOff);
    };
  });

  //auto close mobile menu if viewing on desktop and expands past breakpoint

  React.useEffect(() => {
    function handleResize() {
      window.requestAnimationFrame(() => {
        if (window.innerWidth > 991) {
          setMobileMenuIsOpen(false);
        }
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileMenuIsOpen]);

  //toggles open/close dropdown menu, stops propagation on touch events.

  function toggleMenu(e) {
    e.stopPropagation();
    setDropdownMenuIsOpen((prev) => !prev);
  }

  //toggles open/close for mobile menu.

  function toggleMobileMenu() {
    setMobileMenuIsOpen((prev) => !prev);
  }

  //closes menu if out of the ref bounds.

  function clickOff(e) {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setDropdownMenuIsOpen(false);
    }
  }

  return (
    <>
      {/* Desktop nav */}
      <nav className={nav} ref={navRef}>
        <ul className={ul}>
          <li>
            <Link
              to="/find-your-rank/"
              className={navLink}
              activeStyle={{ color: "#fff" }}
            >
              Compare your NPS
            </Link>
          </li>
          <li>
            <Link to="/#leaderboard" className={navLink}>
              Leaderboard
            </Link>
          </li>
          <li className={dropdown}>
            <button
              className={`${button} ${navLink}`}
              onClick={toggleMenu}
              style={dropdownMenuIsOpen ? { color: "#FFF" } : null}
            >
              Guides
            </button>
            {dropdownMenuIsOpen && (
              <div className={menu} aria-hidden={!dropdownMenuIsOpen}>
                {linkTitles.map((title, i) => {
                  return (
                    <div key={`desktop-${title.id}`}>
                      <Link className={menuLink} to={`/tips/${title.slug}`}>
                        {title.article_title}
                      </Link>
                      {i < linkTitles.length - 1 && <hr />}
                    </div>
                  );
                })}
              </div>
            )}
          </li>
          <li>
            <a
              href="https://www.rocketreferrals.com"
              rel="noreferrer"
              target="_blank"
              className={navLink}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </nav>

      {/* Mobile Nav */}

      <button
        className={mobileMenuIcon}
        title="Mobile menu button"
        onClick={toggleMobileMenu}
      >
        <i className="fas fa-w fa-bars"></i>
      </button>
      <div
        className={mobileMenu}
        style={mobileMenuIsOpen ? { left: "0" } : null}
      >
        {mobileMenuIsOpen && (
          <nav className={`${nav} ${mobileNav}`} style={{ width: "100%" }}>
            <ul className={`${ul} ${mobileNavItems}`}>
              <li style={{ alignSelf: "flex-end", padding: "0" }}>
                <button className={mobileMenuClose} onClick={toggleMobileMenu}>
                  <i className="fas fa-w fa-times"></i>
                </button>
              </li>
              <li>
                <Link
                  to="/find-your-rank/"
                  className={`${navLink} ${mobileLink}`}
                  activeStyle={{ color: "#fff" }}
                >
                  Compare your NPS
                </Link>
              </li>
              <li>
                <Link to="/#leaderboard" className={`${navLink} ${mobileLink}`}>
                  Leaderboard
                </Link>
              </li>
              <li className={dropdown}>
                <button
                  type="button"
                  className={`${button} ${navLink} ${mobileButton}`}
                  onClick={toggleMenu}
                  style={dropdownMenuIsOpen ? { color: "#FFF" } : null}
                >
                  Guides
                </button>
                {dropdownMenuIsOpen && (
                  <div className={menu} aria-hidden={!dropdownMenuIsOpen}>
                    {linkTitles.map((title, i) => {
                      return (
                        <div key={`mobile-${title.id}`}>
                          <Link className={menuLink} to={`/tips/${title.slug}`}>
                            {title.article_title}
                          </Link>
                          {i < linkTitles.length - 1 && <hr />}
                        </div>
                      );
                    })}
                  </div>
                )}
              </li>
              <li>
                <a
                  href="https://www.rocketreferrals.com"
                  rel="noreferrer"
                  target="_blank"
                  className={`${navLink} ${mobileLink}`}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}
